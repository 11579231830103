import { render, staticRenderFns } from "./ListElementDescription.vue?vue&type=template&id=1b6d5204&scoped=true&functional=true&"
import script from "./ListElementDescription.vue?vue&type=script&lang=js&"
export * from "./ListElementDescription.vue?vue&type=script&lang=js&"
import style0 from "./ListElementDescription.vue?vue&type=style&index=0&id=1b6d5204&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "1b6d5204",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/nde/pim/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b6d5204')) {
      api.createRecord('1b6d5204', component.options)
    } else {
      api.rerender('1b6d5204', component.options)
    }
    module.hot.accept("./ListElementDescription.vue?vue&type=template&id=1b6d5204&scoped=true&functional=true&", function () {
      api.rerender('1b6d5204', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/List/ListElementDescription.vue"
export default component.exports